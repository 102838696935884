import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Link, Avatar, Box, Container, Paper, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Customer {
  gender: string;
  id: string;
  lastUpdatedTime: string;
  link: string;
  locale: string;
  name: string;
  pageScopedID: string;
  phoneNumbers: string[] | null;
  profilePhotoUrl: string;
  source: string;
  timezone: number;
}

const useStyles = makeStyles({
  container: {
    marginTop: '20px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: '20px',
    marginBottom: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: '20px',
  },
  dataGrid: {
    flexGrow: 1,
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid #e0e0e0',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#f5f5f5',
      borderBottom: '1px solid #e0e0e0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
  },
});


const CustomerTable: React.FC<{ data: Customer[], loading: boolean }> = ({ data, loading }) => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    { 
      field: 'name', 
      headerName: 'Name', 
      width: 150,
      renderCell: (params) => (
        <Link 
          href={`${params.row.link}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'profilePhotoUrl',
      headerName: 'Profile Photo',
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: any) => (
        <Avatar
          src={params.value || 'https://www.facebook.com/images/fb_icon_325x325.png'}
          alt="Profile Photo"
          sx={{ width: 50, height: 50 }}
        />
      ),
    },
    { 
      field: 'lastUpdatedTime', 
      headerName: 'Last Interacted Time', 
      flex: 1,
      type: 'dateTime',
      valueGetter: (params) => new Date(params),
    },
    { field: 'source', headerName: 'Source', flex: 1 },
    { field: 'gender', headerName: 'Gender', flex: 1 },
    { field: 'locale', headerName: 'Locale', flex: 1 },
  ];

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.header}>
          Customer
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={data}
              columns={columns}
              autoPageSize
              loading={loading}
              slots={{
                toolbar: GridToolbar,
              }}
              className={classes.dataGrid}
            />
          </div>
        )}
      </Paper>
    </Container>
  );
};


const Customer: React.FC<{ accountId: string | null }> = ({ accountId }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      const response = await fetch(`/api/page/${accountId}/customers`);
      const data = await response.json();
      setCustomers(data);
      setLoading(false);
    };

    fetchCustomers();
  }, [accountId]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <CustomerTable data={customers} loading={loading} />
      )}
    </div>
  );
};

export default Customer;
