import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemButton, 
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Avatar,
} from "@mui/material";
import {
  Dashboard,
  ExitToApp,
  ContactMail,
  Menu as MenuIcon,
  Autorenew,
  Group
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";

interface AccountData {
  photoUrl: string;
  name: string;
  accountType: string;
  id: string;
}

interface SideNavigationBarProps {
  accountData: AccountData | null;
  error: string | null;
}

const SideNavigationBar: React.FC<SideNavigationBarProps> = ({
  accountData,
  error,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isCollapsed = useMediaQuery("(max-width: 600px)");
  const [drawerOpen, setDrawerOpen] = useState(!isCollapsed);

  const handleLogout = async () => {
    await fetch("/api/logout", {
      method: "POST",
      credentials: "include",
    });
    navigate("/");
  };

  const handleSupportClick = () => {
    window.open("https://m.me/408901325641502", "_blank");
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }
  return (
    <Box display="flex">
      {isCollapsed && (
        <IconButton
          onClick={toggleDrawer}
          sx={{ position: "fixed", top: 16, left: 16 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: drawerOpen ? 240 : 60,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 240 : 60,
            boxSizing: "border-box",
          },
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          {accountData && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={2}
            >
              <Avatar
                src={accountData.photoUrl}
                sx={{ width: 48, height: 48, mb: 1 }}
              />
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" align="center">
                  {accountData.name}
                </Typography>
                {accountData.accountType === "facebook" && (
                  <FacebookIcon
                    sx={{ ml: 0.5, fontSize: 18, color: "#1877F2" }}
                  />
                )}
              </Box>
            </Box>
          )}
          <List>
            <ListItemButton
              onClick={() => navigate(`/dashboard/`)}
              style={{ cursor: "pointer" }}
            >
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            {accountData && (
              <>
                <ListItemButton
                  onClick={() => navigate(`/dashboard/${accountData.id}/automation`)}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemIcon>
                    <Autorenew />
                  </ListItemIcon>
                  <ListItemText primary="Automation" />
                </ListItemButton>
              </>
            )}
            {accountData && (
              <>
                <ListItemButton
                  onClick={() => navigate(`/dashboard/${accountData.id}/customer`)}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemIcon>
                    < Group/>
                  </ListItemIcon>
                  <ListItemText primary="Customer" />
                </ListItemButton>
              </>
            )}
            <ListItemButton
              onClick={handleSupportClick}
              style={{ cursor: "pointer" }}
            >
              <ListItemIcon>
                <ContactMail />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton
              onClick={handleLogout}
              style={{ cursor: "pointer" }}
            >
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideNavigationBar;
