import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Automation from './Automation/Automation';
import Customer from './Customer/Customer';

interface AccountData {
  id: string;
  name: string;
  photoUrl: string;
  accountType: string;
}

const AccountDetails: React.FC = () => {
  const [data, setData] = useState<AccountData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const hasFetchedData = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  // Function to extract the accountId from the URL
  const extractAccountId = (path: string) => {
    const match = path.match(/\/dashboard\/(\d+)(\/|$)/);
    return match ? match[1] : null;
  };

  const accountId = extractAccountId(currentPath);
  let accountType = 'facebook'; // Default to Facebook
  const fetchAccountData = async () => {
    try {
      let response = await fetch(`/api/page/${accountId}`, { method: 'GET', credentials: 'include' });
      if (response.status === 404) {
        response = await fetch(`/api/instagram-business/${accountId}`, { method: 'GET', credentials: 'include' });
        accountType = 'instagram'; // Update to Instagram if 404 on Facebook
      }

      if (response.status === 401) {
        navigate('/');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch account data');
      }

      const data = await response.json();
      setData({
        id: data.id,
        name: data.name,
        photoUrl: data.photoUrl,
        accountType: accountType,
      });
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setIsLoading(false);
      hasFetchedData.current = true;
    }
  };

  useEffect(() => {
    if (accountId && !hasFetchedData.current) {
      fetchAccountData();
    }
  }, [accountId, navigate]);

  return (
    <Routes>
      <Route path="" element={<Layout data={data} isLoading={isLoading} error={error} />} >
        <Route path="automation" element={<Automation accountId={accountId} />} />
        <Route path="customer" element={<Customer accountId={accountId} />} />
      </Route>
    </Routes>
  );
};

export default AccountDetails;
